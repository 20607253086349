$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-sl: 1920px;

$screen-ex-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
$screen-lg-max: ($screen-xl - 1);
$screen-xl-max: ($screen-sl - 1);

@mixin respond($media) {
    @if $media == ex {
		@media (max-width: $screen-ex-max) { @content }
	}
    @else if $media == sm {
		@media (min-width: $screen-sm) and (max-width: $screen-sm-max) { @content }
	}
	@else if $media == md {
		@media (min-width: $screen-md) and (max-width: $screen-md-max) { @content }
	}
	@else if $media == lg {
		@media (min-width: $screen-lg) and (max-width: $screen-lg-max) { @content }
	}
	@else if $media == xl {
		@media (min-width: $screen-xl) and (max-width: $screen-xl-max) { @content }
	}
    @else if $media == sl {
		@media (min-width: $screen-sl) { @content }
	}
    @else if $media == min-sm {
		@media (min-width: $screen-sm) { @content }
	}
    @else if $media == min-md {
		@media (min-width: $screen-md) { @content }
	}
    @else if $media == min-lg {
		@media (min-width: $screen-lg) { @content }
	}
    @else if $media == min-xl {
		@media (min-width: $screen-xl) { @content }
	}
    @else if $media == max-sm {
		@media (max-width: $screen-sm-max) { @content }
	}
    @else if $media == max-md {
		@media (max-width: $screen-md-max) { @content }
	}
    @else if $media == max-lg {
		@media (max-width: $screen-lg-max) { @content }
	}
    @else if $media == mobile {
		@media (max-width: $screen-sm-max) { @content }
	}
}

@mixin retina() { @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { @content } }