// variables
$haze-color: #f4f6f9;
$dull: #434343;
$light: #fff;
$theme-color-2: rgb(50,179,152);
$theme-color-3: #5BB2BB;
$theme-color-4: #2D5A95;
$font_2: "Circular Std", Lato, sans-serif;
$iconfont: "Font Awesome 5 Free";

select {
  appearance: none;
  -webkit-appearance: none;
}
.select {
  position: relative;
  &::after, &::before {
    content: "\f107";
    font-family: $iconfont;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 700;
    font-size: 1.5rem;
    position: absolute;
    right: 1.5rem;
    top: 16px;
    z-index: 5;
    color: $dull;
  }
  &::after {
    opacity: 0.7;
  }
  &::before {
    top: 14px;
    z-index: 6;
    color: #F9F9F9;
  }
}
.body {
  &_home {
    background: $light !important;
  }
}