select {
  opacity: 0.67;
}

.nav{
  &-link {
    &:hover, &:focus {
      border-bottom: 2px solid #2d5a95;
    }
  }
  &_max {
    max-width: 1920px !important;
    .phone {
      @media screen and (min-width: 1023px) {
        order: 3;
      }
    }
    &.first {
      > div {
        flex: 1;
        .first-screen__nav {
          @media screen and (min-width: 1200px) {
            justify-content: center;
          }
        }
      }
    }
    a {
      font-size: clamp(1rem, 1.1vw, 1.5rem) !important;
      &.btn {
        height: initial !important;
        @media screen and (min-width: 1550px) {
          padding-left: 1rem !important;
          padding-right: 1rem !important;
        }
      }
      @media screen and (min-width: 1550px) {
        margin: 0 1rem;
      }
    }
    @media screen and (min-width: 1024px) {
      width: 100%;
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }

  }
  &bar {
    max-width: 100vw;
    &_clean {
      border-bottom: none !important;
    }
    @media screen and (max-width: 992px) {
      border-bottom: none !important;
    }
  }
  &_item {
    &:last-child {
      @media screen and (max-width: 992px) {
        order: -2;
      }
    }
  }
}

.location {
  &s {
    display: flex;
    flex-flow: row wrap;
    &_center {
      justify-content: center;
    }
  }
  &_btn {
    // background-color: $haze-color;
    display: inline-grid;
    grid-template-columns: 1rem 1fr;
    border-radius: 1.5rem;
    grid-gap: 0.25rem;
    place-items: center;
    margin: 0.7rem 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border: 2px solid $theme-color-3;
    color: $theme-color-3 !important;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
    .fa {
      font-size: 0.8rem !important;
    }
  }
  &_container {
    padding: 75px 0 50px;
    margin-top: 75px;
  }
  &_mute {
    opacity: 0.33;
    pointer-events: none;
  }
}

.btn {
  &_rounded {
    border-radius: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1rem;
    color: #0c4084;
    background-color: transparent;
    display: inline-grid;
    place-items: center;
    padding-top: 0;
    padding-bottom: 0;
    &, &:hover {
      border: 1.5px solid #0c4084 !important;
    }
  }
}

.img {
  &_center {
    display: grid;
    // place-items: center;
  }
}

.bg {
  &_haze {
    background: #f4f6f9;
  }
  &_theme {
    // background: rgba(114,224,198,1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 1280px) {
      min-height: 433px;
    }
  }
}

.grid {
  &_2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    padding: 0 2.5rem;
    grid-gap: 0.5rem 0.75rem;
    margin-top: 1.5rem;
    @media screen and (min-width: 992px) {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      width: 90%;
      max-width: 840px;
      margin-left: auto;
      margin-right: auto;
    }
    li {
      align-items: initial !important;
    }
  }
  &_simple {
    display: grid;
    max-width: 240px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    img {
      display: block;
      width: 100%;
    }
    a:last-child{
      img {
        transform: scale(1.25) translateY(-0.1rem) translateX(-0.2rem);
      }
    }
  }
  &_header {
    grid-column: 1/-1;
  }
  &_auto {
    display: grid;
    max-width: 960px;
    margin: 0 auto 1rem;
    grid-gap: 1rem;
    @media screen and (min-width: 667px) {
      grid-template-columns: 1fr 250px;
    }
  }
}

.text {
  &_light {
    color: #fff;
  }
}

.partner {
  &s {
    &, &_wrap {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }
    display: inline-flex;
    &_wrap {
      @media screen and (max-width: 992px) { 
        padding: 1.5rem 0;
      }
      @media screen and (max-width: 667px) {
        display: grid;
      }
    }
    @media screen and (max-width: 667px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  &_col {
    margin: 0 !important;
    display: grid;
    place-items: center;
    &:not(.subtitle) {
      filter: grayscale(1) opacity(0.6);
    }
    @media screen and (max-width: 667px) {
      place-items: initial;
    }
    &:first-child {
      flex: 1;
      @media screen and (max-width: 667px) { 
        margin-top: 1.5rem;
      }
    }
  }
  &_logo {
    width: 100%;
    max-width: 10rem;
    margin: 1rem;
    opacity: 0.7;
    @media screen and (max-width: 667px) {
      margin: 1rem 1.5rem 0 0;
    }
  }
}

#map {
  width: 100%;
  padding-top: 50%;
  position: relative;
  margin-top: 1rem;
  box-shadow: 0 1rem 4rem rgba(0,0,0,0.1);
  border: 1px solid #eee;
  @media screen and (max-width: 667px) {
    padding-top: 70%;
  }
  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.wrap {
  &, &_min {
    @media screen and (min-width: 1441px) {
      width: 100%;
    }
    margin-left: auto;
    margin-right: auto;
  }
  max-width: 1440px;
  &_min {
    max-width: 1140px;
  }
  &_nav {
    @media screen and (min-width: 1024px){
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.input {
  &_button {
    height: 1px;
    width: 1px;
    opacity: 1;
    visibility: hidden;
    &_submit {
      border-radius: 1.5rem;
      margin-left: auto;
      font-size: 1rem;
      font-weight: 500;
      &:hover {
        opacity: 0.9;
        // background: rgb(53,50,99);
      }
    }
  }
  &_button:checked + &_label {
    background-color: #2d5a95;
    color: #fff;
  }
  &_label {
    background-color: $haze-color;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-align: center;
    cursor: pointer;
    flex: 1;
    &:first-of-type {
      margin-right: 1rem;
    }
  }
  &_sales {
    display: flex !important;
  }
}

.conveyancer {
  &_section {
    margin-top: 1rem !important;
    margin-bottom: 1.5rem !important;
    @media screen and (max-width: 1024px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    h1, h2, h3, h4, h5, b, strong {
      color: #000;
      text-align: left;
      padding: 1rem 0;
    }
    .h2 {
      padding-bottom: 0;
    }
    strong, b {
      padding: 0;
    }
    p, ul, ol {
      text-align: left;
      padding: 0.5rem 0;
      margin-bottom: 0;
      // temporary
      max-width: 840px;
    }
    ul {
      list-style-type: disc;
      padding-left: 1.25rem;
      li {
        list-style: initial;
      }
    }
  }
  &_companies {
    display: grid;
    grid-gap: 2rem;
    width: 100%;
    max-width: 100%;
    padding: 0;
    @media screen and (min-width: 667px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &_company {
    background: #fafafa;
    padding: 0 1rem;
    border-radius: 0.25rem;
    box-shadow: 0 0.1rem 0.4rem rgba(0,0,0,0.07);
    &_header {
      display: grid;
      // grid-template-columns: 2fr 3fr;
      grid-gap: 0.25rem;
      img {
        display: block;
        width: auto;
        max-width: 100%;
        height: 4rem;
        // margin: 0 auto
      }
      figure {
        padding: 1.5rem 0 0 0;
      }
    }
  }
  &_rating {
    display: grid;
    align-items: center;
    grid-gap: 0.5rem;
    grid-template-columns: 1rem 1fr;
    margin: -1rem 0 -0.25rem 0
  }
}

.icon {
  &_small {
    display: inline-block;
    width: 1rem;
    height: 1rem;
  }
}

.footer > div {
  @media screen and (min-width: 768px) {
    // align footer to left
    transform: translateX(-1rem);
  }
}

.form {
  &-control {
    border: 1px solid #eeeeee;
    appearance: none;
    -webkit-appearance: none;
    &:focus {
      border: 1px solid rgba(114,224,198,0.5);
    }
  }
}

.find {
  &_conveyancers {
    margin: 1.5rem auto;
    width: 100%;
    max-width: 100%;
    &, &_form {
      border-radius: 0.5rem;
    }
    &_form {
      display: grid;
      grid-gap: 1.25rem 1rem;
      @media screen and (min-width: 667px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (min-width: 1025px) {
        grid-template-columns: repeat(3, 1fr);
      }
      & > div:last-child {
        grid-column: 1/-1;
      }
    }
  }
  &_wrap {
    &.active {
      @media screen and (max-width: 50rem) {
        padding: 0 1.5rem;
      }
      display: grid;
      z-index: 3;
      position: fixed;
      align-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      background-color: rgba(0,0,0,0.15);
      > div {
        max-width: 50rem;
      }
    }
  }
}

.book {
  &_btn {
    background: $theme-color-3;
    margin-left: initial;
    height: initial;
    border-radius: 0.25rem;
    font-size: 1.25rem;
  }
  &_header {
    font-size: clamp(1.75rem, 2.5vw, 3rem);
  }
  &_conveyancers {
    display: grid;
    grid-template-columns: 1fr;
  }
  &_wrap {
    max-width: 33rem;
    padding: 0 1.5rem;
    > div {
      margin-bottom: 0 !important;
      grid-gap: 0 !important;
    }
    .form-control {
      padding: 0.5rem 1rem !important;
      background: #F9F9F9;
      & + & {
       margin-bottom: 0 !important; 
      }
    }

    .form-group {
      margin-bottom: 1.25rem;
    }

    form {
      grid-gap: 0 !important;
    }
    @media screen and (min-width: 992px) {
      padding: 0;
    }
    @media screen and (min-width: 1920px) {
      max-width: 45rem;
    }
    input, select, label {
      height: initial !important;
      font-size: clamp(1.25rem, 1.5vw, 1.7rem);
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}

.home{
  &_grid {
    display: grid;
    grid-gap: 1rem 5rem;
    background: $light;
    @media screen and (min-width: 992px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: 1920px) {
      grid-template-columns: 1fr 1fr;
    }
    > div:last-child {
      // align-self: end;
      @media screen and (min-width: 992px) {
        margin-right: 1.5rem;
        margin-top: 90px !important;
      }
      @media screen and (min-width: 3600px) {
        margin-top: 100px !important;
      }
    }
  }
  &_hero {
    margin-bottom: 0;
    display: none;
    &.active {
      display: initial;
    }
    img {
      width: 100%;
    }
  }
}

.client {
  &s {
    filter: grayscale(1);
    column-count: 2;
    column-gap: 1rem;
    @media screen and (min-width: 557px) {
      column-count: 3;
    }
    @media screen and (min-width: 992px) {
      column-count: 6;
    }
  }
  &_logo {
    margin: 1rem;
    max-width: 8rem;
  }
}

.h1, .h2, .h3 {
  color: #000;
}

.btn-small {
  min-width: 117px !important;
}

.fa-phone {
  transform: rotate(105deg);
  transform-origin: 50% 50%;
}

.post {
  &-content {
    ul, ol {
      padding-left: 1.25rem;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
    h3 {
      padding: 0.75rem 0;
    }
    h1, h2, h3, h4, h5, b, strong {
      color: #000;
    }
    ul {
      list-style-type: initial !important;
    }
    ol {
      list-style: decimal !important;
    }
    li {
      padding: 0.25rem 0;
      list-style: initial !important;
    }
    li, p {
      font-size: 1.1rem;
    }
    p {
      line-height: 1.7;
    }
  }
  &-author {
    color: $theme-color-2;
  }
  &-title {
    font-size: 1.8rem;
    color: #000;
  }
  &_title {
    &, a {
      color: #000;
    }
  }
  &_card {
    padding: 1.5rem;
    border-bottom: 1px solid  $haze-color;
    box-shadow: 0 0.25rem 2rem rgba(0,0,0,0.09);
    border-radius: 1rem;
    overflow: hidden;
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}