.btn {
    &-primary {
        height: 42px;
        min-width: 150px;
        padding: 9px .75rem 11px;
        background: #29579c;
        border: none;
        font-size: .875rem;
        font-weight: 500;
        text-align: center;
        border-radius: 3px;
        &:hover { background: #0c4084; }
        @include respond(ex) { min-width: 130px; }
    }
    &-outline-primary {
        height: 42px;
        min-width: 150px;
        padding: 8px .75rem 10px;
        background: transparent;   
        border: 1px solid #29579c;        
        font-size: .875rem;
        color: #29579c;
        font-weight: 500;
        text-align: center;
        border-radius: 3px;
        @include respond(ex) { min-width: 130px; }
        &:hover {
            background: #e3ecfa;
            border-color: #e3ecfa;
            color: #0c4084;
        }
        &:not(:disabled):not(.disabled):active {
            background-color: transparent;
            border-color: #0062cc;
            color: #0062cc;
        }
    }
    &:active:focus, &:focus { outline: none; box-shadow: none!important; }
}

.form {
    &-control {
        // height: 42px;
        border: solid 1px #bcbcbc;
        border-radius: 3px;
        font-family: "Lato", sans-serif;
        font-size: 16px;
        color: #434343;
        box-sizing: border-box;
        appearance: none;
        line-height: 1.5;
        padding: .375rem 1.25rem .5rem;
        &::placeholder { color: #797979; }
        &:focus {
            outline: none;
            box-shadow: none;
            border-color: #bcbcbc;
        }
    }
    &-group {
        position: relative;
        &_price {
            &:before {
                font-size: 1.25rem;
                color: #29579c;
                font-weight: 900;
                position: absolute;
                top: 50%;
                left: 16px;
                transform: translate(0,-50%);
                content: "$";
            }
            .form-control { padding-left: 40px; }
        }
    }
    &-btn {
        height: 52px;
        padding-left: 1rem;
        padding-right: 1rem;
        min-width: auto!important;
    }
}

.custom-select { padding-right: 32px; }

.form-find-conveyancers {    
    margin: -8px;
    .form-group { 
        padding: 8px;
        margin-bottom: 0;
        flex-basis: 100px;
        flex-grow: 1;
        &__btn {
            flex-basis: 50px;
            flex-grow: 0;
        }
    }
    .form-group:first-child {
        @include respond(mobile) { flex: 0 0 100%; }
        flex-grow: 2;
    } 
    @include respond(sm) { .form-group:nth-child(3) { flex-grow: 1 } }
    @include respond(ex) { 
        .form-group:nth-child(2) { flex: 0 0 100%; }
        .form-group:nth-child(3) { flex-grow: 1 }
    }
}

.form-track-property {
    margin: -8px;    
    .form-group { 
        padding: 8px;
        margin-bottom: 0;
        flex-basis: 100px;
        flex-grow: 1;
        &__btn {
            flex-basis: 50px;
            flex-grow: 0;
        }
    }
    @include respond(sm) { .form-group { flex: 0 0 50%; } }
    @include respond(ex) { .form-group { flex: 0 0 100%; } }
}
