$e-blue: rgb(58,132,212);
$e-gray: rgb(249,249,249);
$e-light: #fff;
$e-border: #d8d8d8;
$e-haze: #eee;
$e-theme: rgb(50,179,152);
$e-text: #111;
$e-normal: #00234B;
$e-heading: #3e3e3e;
body {
  background: $e-gray;
}
.starred {
  color: $e-theme;
  font-size: 0.6rem;
}
.entity {
  background: $e-light;
  border-radius: 0.5rem;
  border: 0.5px solid $e-haze;
  padding: 0.5rem 1rem;
  box-shadow: -2px 5px 9px rgba(0, 0, 0, 0.0584572);
  &_wrap {
    display: grid;
    grid-gap: 1.5rem;
    color: $e-normal;
    @media screen and (min-width: 667px) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    @media screen and (min-width: 1440px) {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
  }
  &_whole {
    grid-column: -1/1;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    overflow: hidden;
    &:not(:nth-child(5)) {
      height: 0;
      padding: 0;
      opacity: 0;
    }
    &:nth-child(5) {
     position: relative;
     overflow: visible;
     box-shadow: none;
     &::after {
       content: "";
       position: absolute;
       background: linear-gradient(transparent, $e-gray);
       z-index: 3;
       top: 0;
       bottom: 0;
       left: -0.25rem;
       right: -0.25rem;
       transform: translateY(1rem);
       box-shadow: none;
     }
    }
  }
  &_grid {
    width: 100%;
    display: grid;
    grid-gap: 2rem;
    @media screen and (min-width: 1025px) {
     grid-template-columns: 3fr 2fr;
    }
  }
  &_group {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid $e-blue;
    border-radius: 1.5rem;
    &_btn {
      color: $e-blue;
      border-radius: 1.5rem;
      padding: 0.33rem 1.5rem;
      text-align: center;
      font-size: 0.8rem;
      cursor: pointer;
      &.active {
        color: $e-light;
        background: $e-blue;
      }
    }
  }
  &_image {
    &, img {
      width: 3.5rem;
      border-radius: 50%;
      display: inline-block;
      margin-right: 1rem !important;
    }
  }
  &_heading {
    color: $e-heading;
    font-weight: 700;
    font-size: 2rem;
  }
  &_title {
    color: $e-normal;
    font-size: 1rem;
    font-weight: 700;
  }
  &_fade {
    opacity: 0.5;
  }
  &_price {
    display: inline-block;
    position: relative;
    font-size: 0.8rem;
    padding-top: 0.5rem;
    &::before {
      content: "";
      height: 1px;
      background-color: $e-text;
      position: absolute;
      bottom: 40%;
      left: 0;
      right: 0;
      transform-origin: 50% 50%;
      transform: rotate(-15deg);
    }
  }
  &_offer {
    color: $e-blue;
    font-weight: 600;
    font-size: 1.33rem;
  }
  &_row {
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    > div:first-child {
      margin-right: 0.5rem;
    }
  }
  &_whole &_row {
    margin: 0;
  }
  &_flex {
    flex: 1;
  }
  &_small {
    font-size: 0.8rem;
    vertical-align: top;
    &er {
      font-size: 0.67rem;
    }
  }
  &_btn {
    background: $e-blue;
    color: $e-light;
    font-size: 0.67rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    height: 1.67rem;
    border-radius: 0.25rem;
    cursor: pointer;
    border: 1px solid $e-blue;
    display: inline-flex;
    align-items: center;
    &_hollow {
      background: transparent;
      color: $e-blue;
    }
  }
  &_container {
    padding: 0 1.5rem;
  }
  &_contact {
    font-size: 0.7rem;
  }
  &_form {
    // display: grid;
    padding-bottom: 1.5rem;
    &::before {
      content: "";
      position: absolute;
      padding: 0.5rem;
      height: 0.7rem;
      width: 0.7rem;
      z-index: 5;
      background: url(/assets/images/locale-icon.svg);
      background-repeat: no-repeat;
      background-size: 75%;
      background-position: 50% 50%;
      transform: translate(0.5rem, 1.33rem);
    }
  }
  &_map {
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  &_field {
    border: 1px solid $e-border;
    border-radius: 0.25rem;
    background: transparent;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    &_search {
      width: 100%;
      position: relative;
      padding-left: 1.75rem;
      outline-offset: initial;
      margin-bottom: 0.75rem;
    }
    &::placeholder {
      opacity: 0.5;
      font-size: 0.8rem;
    }
  }
  &_text {
    color: $e-text;
  }
}
