.h {
  &_1, &_2 {
    font-weight: 900;
    font-family: $font_2;
  }
  &_1 {
    font-size: 33px;
    color: $dull;
  }
  &_2 {
    color: $theme-color-3;
    font-size: 25px;
  }
}

.text {
  &_light {
    font-weight: 300;
    color: #fff ; //$dull
  }
}