$map: (
0: 0,
1: .25rem,
2: .5rem,
3: 1rem,
4: 1.5rem,
5: 2rem,
6: 2.5rem,
7: 3rem,
8: 3.75rem
);

.m {
    @for $i from 0 through 8 {
        &-#{$i} { margin: map-get($map,$i)!important; }
        &t-#{$i} { margin-top: map-get($map,$i)!important; }
        &r-#{$i} { margin-right: map-get($map,$i)!important; }
        &b-#{$i} { margin-bottom: map-get($map,$i)!important; }
        &l-#{$i} { margin-left: map-get($map,$i)!important; }
        &x-#{$i} { margin-left: map-get($map,$i)!important; margin-right: map-get($map,$i)!important; }
        &y-#{$i} { margin-top: map-get($map,$i)!important; margin-bottom: map-get($map,$i)!important; }
    }   
    @each $breakpoint in sm, md, lg, xl {
        @include respond(min-#{$breakpoint}) {
            @for $i from 0 through 8 {
                &-#{$breakpoint}-#{$i} { margin:map-get($map,$i)!important; }
                &t-#{$breakpoint}-#{$i} { margin-top: map-get($map,$i)!important; }
                &r-#{$breakpoint}-#{$i} { margin-right: map-get($map,$i)!important; }
                &b-#{$breakpoint}-#{$i} { margin-bottom: map-get($map,$i)!important; }
                &l-#{$breakpoint}-#{$i} { margin-left: map-get($map,$i)!important; }
                &x-#{$breakpoint}-#{$i} { margin-left: map-get($map,$i)!important; margin-right: map-get($map,$i)!important; }
                &y-#{$breakpoint}-#{$i} { margin-top: map-get($map,$i)!important; margin-bottom: map-get($map,$i)!important; }
            } 
        }
    } 
}

.p {
    @for $i from 0 through 8 {
        &-#{$i} { padding: map-get($map,$i)!important; }
        &t-#{$i} { padding-top: map-get($map,$i)!important; }
        &r-#{$i} { padding-right: map-get($map,$i)!important; }
        &b-#{$i} { padding-bottom: map-get($map,$i)!important; }
        &l-#{$i} { padding-left: map-get($map,$i)!important; }
        &x-#{$i} { padding-left: map-get($map,$i)!important; padding-right: map-get($map,$i)!important; }
        &y-#{$i} { padding-top: map-get($map,$i)!important; padding-bottom: map-get($map,$i)!important; }
    }   
    @each $breakpoint in sm, md, lg, xl {
        @include respond(min-#{$breakpoint}) {
            @for $i from 0 through 8 {
                &-#{$breakpoint}-#{$i} { padding: map-get($map,$i)!important; }
                &t-#{$breakpoint}-#{$i} { padding-top: map-get($map,$i)!important; }
                &r-#{$breakpoint}-#{$i} { padding-right: map-get($map,$i)!important; }
                &b-#{$breakpoint}-#{$i} { padding-bottom: map-get($map,$i)!important; }
                &l-#{$breakpoint}-#{$i} { padding-left: map-get($map,$i)!important; }
                &x-#{$breakpoint}-#{$i} { padding-left: map-get($map,$i)!important; padding-right: map-get($map,$i)!important; }
                &y-#{$breakpoint}-#{$i} { padding-top: map-get($map,$i)!important; padding-bottom: map-get($map,$i)!important; }
            } 
        }
    }
}


