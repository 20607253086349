  
  @media(min-width:992px)
  {
    .row
    {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  
  .first-screen {
    &__list.bullet-point {
      li {
        
        &:before {
          
          margin-bottom: auto !important;
          margin-top: 10px !important;
        }
      }
    }
    
    &__collapse-btns {
      @include respond(max-md) {
        display: none;
      }
    }
  }
  
  .pricing-box-container
  {
    max-width: 1250px !important;
  }
  

  
  

  .second-screen {
    position: relative;
    &__content {
      padding-top: 50px;
      padding-bottom: 90px;
      position: relative;
      z-index: 2;
      @include respond(max-md) { padding-bottom: 32px; }
      @include respond(ex) { padding-top: 64px; }
      &_conveyancer { @include respond(ex) { padding-bottom: 0; } }
    }
    &__nav {
      display: flex;
      flex-direction: row;
      @include respond(max-md) { display: none; }
      .nav-item { 
        margin-right: 1.75rem;            
      }
      .nav-link {
        padding-left: 0;
        padding-right: 0;
        font-size: 19px;
        color: #2d5a95!important;
      }
      .nav-item.active .nav-link {
        border-bottom: 2px solid #2d5a95;
      }
    }
    &__top-btns {
      position: absolute;
      top: 19px;
      right: 15px;
      z-index: 2;
      @include respond(max-md) {
        display: none;
      } 
    }
    &__logo {
      width: 350px;
      @include respond(ex) {
        width: 180px;
      }
    }
    &__title {
      margin-bottom: 1rem;
      font-size: 30px;
      font-weight: 900;
      color: #434343;
      @include respond(ex) { font-size: 20px; font-weight: 800; }
    }
    &__list {
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0;
        margin: 0;
        font-size: 18px;
        color: #282828;
        font-weight: 300;
        line-height: 1.3;
        @include respond(ex) { font-size: 15px; }
        &:before {
          width: 18px;
          height: 14px;
          min-width: 18px;
          margin-right: .75rem;
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAxOCAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojMkQ1QTk1O308L3N0eWxlPjxnPjxnIGlkPSJjaGVjayI+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSI1LjgsMTAuOSAxLjUsNi43IDAuMSw4LjEgNS44LDEzLjggMTcuOSwxLjYgMTYuNSwwLjIgIi8+PC9nPjwvZz48L3N2Zz4=);
          content: "";
          margin-bottom: auto !important;
          margin-top: 10px !important;
        }
      }
    }
    &-img {
      display: block;
      width: 138%;
      height: 100%;
      background: url('../images/first-screen-img.png');
      background-size: contain;
      background-repeat: no-repeat!important;
      background-position: bottom right!important;
      position: absolute;
      bottom: 0;
      right: 15px;
      content: "";
      z-index: 1;
      &_conveyancer { 
        background: url('../images/conveyancer-first-screen.svg');
        right: 0;
        @include respond(lg) { width: 110%; }
      }
      &_mortgage-broker { 
        background: url('../images/mortgage-broker-first-screen.svg');
        background-position: center right!important;
        @include respond(lg) { width: 100%; }
      }
      &_real-estate { 
        background: url('../images/real-estate-first-screen.svg');
        @include respond(min-md) { right: 15px; }
        @include respond(lg) { width: 110%; }
      }
      &_buyer-seller { 
        background: url('../images/buyer-seller-first-screen.svg');
        @include respond(min-lg) { right: 0; }
      }


      @include respond(max-md) {
        width: calc(100% + 30px);
        right: -15px;
        left: -15px;
        position: relative;
        &:before {
          display: block;
          padding-top: 70%;
          content: "";
        }
      }
    }

    .first-screen-img.first-screen-img_developer { 
      background: url('../images/developer-first-screen.jpg');
      @include respond(min-lg) { right: 0; }
    }
  

    

    .play-video {
      display: block;
      width: 124px;
      height: 124px;
      background: rgba(12,64,132,.35);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      transition: all .2s ease-out;
      &:before {
        width: 124px;
        height: 124px;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAxMjQgMTI0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMjQgMTI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I0ZGRkZGRjt9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNODMuNCw2NS4zbC0zMSwxOS40Yy0wLjYsMC40LTEuMywwLjYtMi4xLDAuNmMtMC42LDAtMS4zLTAuMi0xLjktMC41Yy0xLjItMC43LTItMi0yLTMuNFY0Mi42YzAtMS40LDAuOC0yLjcsMi0zLjRjMS4yLTAuNywyLjctMC42LDMuOSwwLjFsMzEsMTkuNGMxLjEsMC43LDEuOCwyLDEuOCwzLjNTODQuNiw2NC42LDgzLjQsNjUuM3oiLz48L3N2Zz4=);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: all .2s ease-out;
        content: "";
      }
      @include respond(ex) {
        &, &:before {
          width: 74px;
          height: 74px;
        }
      }
      &:hover {
        background: rgba(12,64,132,.55); 
        &:before {
          transform: translate(-50%,-50%) scale(.95);
        }
      }
    }
    &__sp-title {
      margin-bottom: 1rem;
      font-size: 54px;
      color: #5084d1;
      font-weight: 900;
      position: relative;
      z-index: 2;
      @include respond(ex) { font-size: 36px; }
    }
    &__sp-text {
      font-size: 23px;
      color: #242424;
      font-weight: 800;
      line-height: 1.3;
      position: relative;
      z-index: 2;
      @include respond(ex) { font-size: 19px; }
    }
  }
  
  //contentbox
  .content-box {
    padding-top: 60px;
    padding-bottom: 150px;
    @include respond(ex) {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }
  
  //pricing heading
  
  .heading{
    h3{
      font-size: 1.60rem !important;
    }
  }
  
  .price
  {
    .grey{
      font-size: 17px !important;
    }
  } 
  
  .price-box
  {
    width: 100%;
  }
  //section box small
  
  .section-box-sm
  {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  
  
  //modal
  
  .modal {
    &-title {
      margin-bottom: 1.75rem;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      
      @include respond(ex) {
        margin-bottom: 1.5rem;
        font-size: 24px;
      }
    }
    
    &_payment-method .modal-dialog, &_lg .modal-dialog {
      @include respond(max-md) {
        max-width: 100%;
      }
      
      @include respond(min-lg) {
        min-width: 850px;
      }
    }
    
    .modal-content {
      padding: 1.75rem;
      border: none;
      border-radius: 0;
    }
    
    &-header, &-body, &-footer {
      padding: 0;
      border: none;
    }
    
    button.close {
      width: 24px;
      height: 24px;
      margin: 0 0 0 auto;
      padding: 0;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0OCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDggMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojQjdCN0I3O30uc3Qxe2ZpbGw6IzUwODREMTt9PC9zdHlsZT48Zz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTMuNCwxMmw2LjMtNi4zYzAuNC0wLjQsMC40LTEsMC0xLjRjLTAuNC0wLjQtMS0wLjQtMS40LDBMMTIsMTAuNkw1LjcsNC4zYy0wLjQtMC40LTEtMC40LTEuNCwwYy0wLjQsMC40LTAuNCwxLDAsMS40bDYuMyw2LjNsLTYuMyw2LjNjLTAuNCwwLjQtMC40LDEsMCwxLjRDNC41LDE5LjksNC43LDIwLDUsMjBzMC41LTAuMSwwLjctMC4zbDYuMy02LjNsNi4zLDYuM2MwLjIsMC4yLDAuNSwwLjMsMC43LDAuM2MwLjMsMCwwLjUtMC4xLDAuNy0wLjNjMC40LTAuNCwwLjQtMSwwLTEuNEwxMy40LDEyeiIvPjwvZz48Zz48cGF0aCBjbGFzcz0ic3QxIiBkPSJNMzcuNCwxMmw2LjMtNi4zYzAuNC0wLjQsMC40LTEsMC0xLjRjLTAuNC0wLjQtMS0wLjQtMS40LDBMMzYsMTAuNmwtNi4zLTYuM2MtMC40LTAuNC0xLTAuNC0xLjQsMGMtMC40LDAuNC0wLjQsMSwwLDEuNGw2LjMsNi4zbC02LjMsNi4zYy0wLjQsMC40LTAuNCwxLDAsMS40YzAuMiwwLjIsMC41LDAuMywwLjcsMC4zczAuNS0wLjEsMC43LTAuM2w2LjMtNi4zbDYuMyw2LjNjMC4yLDAuMiwwLjUsMC4zLDAuNywwLjNzMC41LTAuMSwwLjctMC4zYzAuNC0wLjQsMC40LTEsMC0xLjRMMzcuNCwxMnoiLz48L2c+PC9zdmc+);
      background-size: 48px 24px;
      opacity: 1;
      
      &:focus {
        outline: none;
      }
      
      &:hover {
        background-position: right;
      }
    }
  }
  
  
  //content padding bottom 
  
  .p-bottom
  {
    padding-bottom: 0px !important;
  }
  
  //shadowbox
  
  .shadow-box {
    background-color: #fff;
    padding: 35px 35px;
    width: 100%;
    border: 1px solid #ededed;
    
    .header {
      align-content: center;
      margin: 0px 10px 30px 20%;
    }
  }
  
  //small textbox
  .textbox-sm
  {
    height: 35px !important;
  }
  
  //navbar top logo position
  
  .navbar-brand
  {
    
    
    //  @media(min-width:1565px) and (max-width:1800px)
    //  {
      //      left: 40px !important;
      //  }
      // left: 145px !important;
    }
    
    // .first-screen__top-btns .btn {
      //     @media(max-width:1199px) {
        //         min-width: auto;
        //     }
        //     }
        
        .center {
          @media(max-width:1569px){
            max-width: 960px !important;
          }
          
          
        }
        
        
        //pricing box
        
        .subtitle
        {
          font-size: 18px !important;
          @media(min-width:768px) and (max-width:990px)
          {
            font-size: 15px !important;
          }
        }
        
        .small-text
        {
          @media(min-width:768px) and (max-width:990px)
          {
            font-size: 0.9rem !important;
          }
        } .footer .text_light {
    color: #fff !important;
}