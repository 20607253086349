.br {
    display: block;
    &-sm, &-md, &-lg, &-xl { display: none; }
    &-sm {        
        @include respond(min-sm) {
            display: block;
            &-none { display: none; }
        }
    }
    &-md {        
        @include respond(min-md) { 
            display: block;
            &-none { display: none; }
        }        
    }
    &-lg {        
        @include respond(min-lg) { 
            display: block;
            &-none { display: none; }
        }
    }
    &-xl {        
        @include respond(min-xl) {
            display: block;
            &-none { display: none; }
        }
    }
}